/* Style for the password validation list */
.password-validations {
    margin-top: 10px;
    padding-top: 10px;
    padding-left: 10px;
    list-style: none;
    font-size: 14px;
    color: #888;
}

/* Style for the valid validation */
.password-validations li.valid {
    color: #4caf50;
    position: relative;
}

.password-validations li.valid:before {
    content: "\2713";
    /* Checkmark icon */
    position: absolute;
    left: -20px;
}

/* Style for the invalid validation */
.password-validations li:not(.valid) {
    color: #ff7e56;
}

/* Additional style for the special character validation */
.password-validations li.specialChar::before {
    content: "\2718";
    /* Cross icon */
    position: absolute;
    left: -20px;
}
