/* General Styles */
.public-view-style *,
.public-view-style *::before,
.public-view-style *::after {
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    font-size: 100%;
    text-decoration: none;
    scroll-behavior: smooth;
}

.public-view-style *:not(i) {
    margin: 0;
}

body {
    width: 100%;
    letter-spacing: 0.15mm;
    position: relative;
    background: #f3f3f3;
    color: #2a2a2a;
    font-family: sans-serif;
}

a {
    color: blue;
}

/* Navigation */
.nav-container {
    width: 100%;
    padding: 8px 24px;
    background: #010203;
}

.nav-container .nav {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-container .nav .nav-logo {
    height: 48px;
}

/* Main Content */
.main-content {
    width: 100%;
    padding: 40px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.login-card {
    margin: 0 auto;
    width: 100%;
    max-width: 420px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.register-card {
    display: flex;
    flex-direction: row;
}

.main-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signin-section {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 10px;
    flex: 1;
}

.forgot-password-section {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 10px;
    flex: 1;
}

.registration-section {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    gap: 10px;
    width: 50%;
}

.registration-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-header {
    margin-top: 15px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    letter-spacing: 0.15mm;
}

.disclaimer {
    font-size: 0.6em;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    text-align: center;
}

.logo {
    width: 50%;
    display: block;
    margin: 0 auto;
}

.input-group {
    width: 100%;
    position: relative;
    margin-top: 24px;
}

.input-email {
    width: 100%;
    border: 1px solid #c2c8d0;
    border-radius: 8px;
    font-size: 15px;
    padding: 12px 10px;
}

.input-group i {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

.btn-next {
    width: 100%;
    height: 44px;
    font-weight: 700;
    font-size: 15px;
    border-radius: 999px;
    cursor: pointer;
    margin-top: 24px;
    background: #f1c44f;
    color: #fff;
    transition: background 0.1s ease-in-out;
}

.btn-next:hover {
    background: #e5b73e;
}

.register-redirect,
.forgot-password-redirect {
    padding-top: 1.5rem;
    font-size: 1rem;
}

/* Social Login */
.or-legend {
    display: flex;
    align-items: center;
    margin: 24px 0;
}

.or-legend .vertical-line {
    flex: 1;
    height: 1px;
    background: #d5d5d5;
}

.or-legend .or-text {
    margin: 0 12px;
    color: #6a6666;
    font-size: 15px;
}

/* Social Login */
.other-sign {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.other-sign-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    gap: 10px;
    padding: 12px 16px;
    margin-top: 16px;
    border: 1px solid #c2c8d0;
    border-radius: 32px;
    cursor: pointer;
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    text-align: center;
}

.other-sign-list:hover {
    background-color: #f3f3f3;
}

.social-login-icon {
    font-size: 24px;
    color: #2a2a2a;
}

.social-login-text {
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #2a2a2a;
    white-space: nowrap;
}

.join-slack-btn {
    padding-top: 20px;
}

.user-auth-helpers {
    text-align: left;
}

/* Footer */
footer {
    font-weight: 300;
    font-size: 14px;
}

footer a {
    color: #203755;
}

@media (max-width: 480px) {
    .social-login-icon {
        font-size: 20px;
        margin-right: 8px;
    }

    .social-login-text {
        font-size: 14px;
    }

    .other-sign-list {
        padding: 10px 12px;
    }
}

@media (min-width: 1024px) {
    /* Show the demo on bigger screen */
    .register-card {
        flex-direction: row;
    }
    .main-content .register-card {
        max-width: 840px;
    }
    .registration-section {
        /*flex: 1;*/
        border-right: 1px solid #c2c8d0;
        border-right: 1px solid #c2c8d0;
    }
    .demo-section {
        flex: 1;
        display: block;
    }
}

@media (max-width: 1023px) {
    /* Hide the demo on smaller screen */
    .main-content .login-card,
    .main-content .signin-card,
    .main-content .register-card {
        max-width: 420px;
    }
    .demo-section {
        display: none;
    }
    .registration-section {
        width: 100%;
        border-right: none;
    }
}

@media (max-width: 648px) {
    .main-content .login-card {
        width: 100%;
    }
}
@media (max-width: 648px) {
    .main-content {
        padding: 16px 16px 118px 16px;
    }
}

.main-content .register-demo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    flex: 1;
}
.main-content .register-demo__title {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 48%;
    padding-bottom: 30px;
    text-align: center;
    color: #333;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
}
/* Animation bellow the demo__title */
.main-content .register-demo__title::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #f1c44f;
    animation: underline-animation 3s ease-in-out infinite;
}
@keyframes underline-animation {
    0% {
        left: -100%;
    }
    50% {
        left: 100%;
    }
    100% {
        left: 100%;
    }
}

.main-content .register-demo__image-container {
    position: relative;
    margin-top: -20px;
    width: 12rem;
    height: 12rem;
}

.main-content .register-demo__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

footer {
    font-weight: 300;
    font-size: 14px !important;
}

footer a {
    color: #203755 !important;
}
.disclaimer {
    font-size: 0.6em;
    padding-top: 0.5rem;
}

.register {
    margin-top: 24px;
    padding: 0 40px 30px 40px;
    display: block;
    text-align: center;
    font-size: 15px;
    letter-spacing: 0.01mm;
}

.signup-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.email {
    word-wrap: break-word;
    overflow-wrap: break-word;
}
