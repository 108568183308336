.page-home {
    margin: 0;
}

.cursor-pointer,
[role="button"] {
    cursor: pointer;
}

.Capitalize {
    text-transform: capitalize;
}

/* fix mui */
.MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.6) !important;
}

/* fix disabled */
.Input .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
}

/* TEST */
.TesterContainer {
    padding: 20px 50px;
    background-color: antiquewhite;
    position: fixed;
    border: 1px solid #000;
    top: 0;
    right: 0;
    z-index: 999999;
}

/* FIX AG-GRID */
.ag-cell-focus,
.ag-cell-no-focus {
    border: 1px solid transparent !important;
}

/* Override hover color for column headers */
.ag-theme-material .ag-header-cell:hover {
    background-color: transparent !important;
}

::ng-deep .ag-cell:focus {
    border: 1px solid transparent !important;
    outline: none;
}

.MuiInputBase-input {
    padding: 10px 14px !important;
}

/* COLORS */
.danger {
    color: #f50057;
}

/* TODO: Keep the comments here for the sticky container */
.sticky-container {
    /* width: 50%; */
    margin: auto 0;
    /* for safari */
    position: sticky;
    /* top: 0px; */
}

.sticky-container-sticky {
    z-index: 9999;
}

.sticky-container.sticky-container-sticky {
    top: 20px;
    text-align: right;
    margin-right: 50px;
}

@keyframes highlightFade {
    0% {
        background-color: #d4e2f2;
    }

    100% {
        background-color: inherit;
    }
}

.highlightRow {
    animation: highlightFade 2s forwards !important;
}

.ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(
        .ag-cell-range-single-cell
    ) {
    border-left: 0 !important;
}

.ag-pinned-right-header {
    border-left: none !important;
}

/* Application Controllers */
.font-style-italic {
    font-style: italic;
}

.Controller {
    margin-bottom: 30px;
}

#create-application .Controller .MuiTextField-root {
    width: 50%;
}

.label {
    font-weight: 600;
    margin-top: 6px;
    margin-bottom: 5px;
}

.DisableComponent {
    opacity: 0.5;
    pointer-events: none;
}

.font-style-italic {
    font-style: italic;
}

.info-text {
    text-align: center;
}

.info-text a {
    text-decoration: underline;
    color: black;
}

.info-text a:visited {
    color: black;
}

.info-text a:hover {
    color: black;
}

.page {
    margin-top: 1.5rem;
}
