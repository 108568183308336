.select-one-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px;
    padding: 5px;
}

.grid-item.selected {
    background-color: #e0e0e0;
}

.grid-item img {
    max-width: 50%;
    max-height: 70%;
    display: block;
    margin: auto;
}

.select-one-grid {
    margin: 20px;
    justify-content: flex;
    padding: 20px 20px 0px 20px;
}

.grid-item {
    cursor: pointer;
    padding: 10px;
    border: 2px solid transparent;
    transition: background-color 0.3s;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.selected,
.grid-item.selected {
    background-color: #e5f6fd;
    border: 1px solid #0cb0f6;
}

.grid-item:hover {
    background-color: #e5f6fd;
}

.selected {
    background-color: #e0e0e0;
}

.grid-item.disabled {
    cursor: not-allowed;
    opacity: 0.4;
    .label {
        margin-left: 8px;
    }
    .controller-framework-label {
        display: inline-flex;
    }
}
